<template>
  <div class="auth-complete">
    <div class="auth-complete__icon primary">
      <img
        width="48px"
        :src="require('@/assets/images/icons/auth-message.svg')"
        alt=""
      />
    </div>
    <div class="text-center">
      <span class="font-24 d-block">Your account registration is done!</span>
      <span class="font-18 font-500 d-block mt-6"
        >verification email sent to</span
      >
      <span class="font-18 d-block">{{ email }}</span>
      <p class="font-12 mb-0 mt-7">
        sometimes taking 1 to 5 minutes to send Email
      </p>
    </div>

    <!-- btn -->
    <!-- <v-btn
      class="mt-2 radius-15"
      height="45px"
      color="primary"
      elevation="0"
      block
      @click="$router.push('/verify')"
      ><span class="white--text font-16">Go To Verify</span></v-btn
    > -->
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.auth-complete {
  position: relative;
  padding-top: 80px;

  &__icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -26px;
    margin: auto;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
