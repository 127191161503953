<template>
  <div class="auth-page">
    <div
      class="w-100 flex-grow-1 d-flex flex-column justify-center align-center"
    >
      <!-- head -->
      <div class="text-center">
        <img
          class="cursor-pointer"
          @click="$router.push('/')"
          :src="require('@/assets/images/global/logo-blue.svg')"
          alt=""
        />
        <span class="font-24 icon-black--text d-block mt-4"
          >Create an account to <br />
          get started!</span
        >
      </div>

      <!-- content -->
      <div class="auth-page__content radius-15 py-8 px-5 mt-7">
        <!-- email -->
        <v-form
          v-model="valid"
          ref="signupForm"
          aria-autocomplete="off"
          autocomplete="off"
        >
          <input type="text" style="display: none" autocomplete="false" />
          <div class="mt-5">
            <span class="d-block font-16 icon-black--text">Email Address</span>
            <base-input
              name="new-email"
              v-model="form.email"
              class="mt-1"
              placeholder="example@gmail.com"
            ></base-input>
          </div>
          <!-- password -->
          <div class="mt-5 mb-1">
            <span class="d-block font-16 icon-black--text">Password</span>
          </div>
          <base-input
            v-model="form.password"
            name="new-password"
            type="password"
            placeholder="*********"
            autocomplete="new-password"
          ></base-input>

          <!-- btn -->
          <v-btn
            class="mt-6 radius-15"
            height="45px"
            color="primary"
            elevation="0"
            block
            @click="submitHandler"
            ><span class="white--text font-16">Sign Up</span></v-btn
          >
        </v-form>
      </div>
      <!-- bottom btn -->
      <router-link to="/signin">
        <div
          class="primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn d-flex align-center justify-center"
        >
          <span class="font-16 icon-black--text d-inline-block mr-1"
            >Already have an account?
          </span>
          <span class="font-16 blue--text font-500">Sign in.</span>
        </div>
      </router-link>
      <!-- bottom btn -->
      <div
        class="primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn"
        @click="$router.push('/plans')"
      >
        <span class="font-16 icon-black--text font-16"
          >See Prices and plans
        </span>
      </div>
      <!-- bottom btn -->

      <!-- hold signup with google -->
      <!-- <div
        class="primary-border px-5 py-3 text-center mt-6 radius-15 hover-btn d-flex align-center justify-center"
        @click="$router.push('/plans')"
      >
        <img
          class="mr-3"
          :src="require('@/assets/images/icons/google.svg')"
          alt=""
        />
        <span class="font-16 icon-black--text font-16"
          >Sign up with google
        </span>
      </div> -->

      <v-dialog
        v-model="dialog"
        max-width="380"
        content-class="white radius-15 px-5 pb-5 overflow-initial"
      >
        <register-complete
          @close="handleCloseDialog"
          :email="form.email"
        ></register-complete>
      </v-dialog>
    </div>
    <div class="privacy-terms-links mt-3 font-16 font-500">
      <a href="https://ofero.co/privecy-and-policy/" target="_blank"
        >Privacy & Policy</a
      >&nbsp;|&nbsp;<a href="https://ofero.co/terms/" target="_blank">Terms</a>
    </div>
  </div>
</template>

<script>
import RegisterComplete from "../../../components/dialogs/RegisterComplete.vue";
export default {
  components: { RegisterComplete },
  data: () => ({
    dialog: false,
    valid: false,
    form: {
      email: "",
      password: "",
    },
    response: {},
  }),
  watch: {
    dialog: {
      handler() {
        if (!this.dialog) {
          if (this.response.user) {
            this.$router.go("/plans");
          } else {
            this.$router.go("/admin/packages");
          }
        }
      },
    },
  },
  methods: {
    handleCloseDialog() {
      this.dialog = false;
      this.$router.go("/plans");
    },
    async submitHandler() {
      let valid = this.$refs.signupForm.validate();
      if (!valid) return;
      try {
        // not using FormData because it automatically sets the "Content-Type" to "multipart/form-data"
        const res = await this.$axios.post(
          process.env.VUE_APP_SIGNUP,
          this.form
        );
        if (res.data) {
          this.$store.dispatch("showSnack", {
            text: "Registered successfully",
            color: "success",
          });
          this.dialog = true;
        }
      } catch (err) {
        this.$store.dispatch("showSnack", {
          text: err.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.auth-page {
  max-width: 380px;
  width: 100%;
  position: relative;
  padding: 20px 0px;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &__content {
    background: $bg-grey;
  }

  & > div {
    & > * {
      width: 100%;
    }
  }

  .privacy-terms-links {
    a {
      color: black;

      &:hover {
        color: #0195a9;
      }
    }
  }
}
</style>
